<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="key"></router-view>
  </div>
</template>
<script>
export default {
  name: "app",
  computed: {
    key() {
      return this.$route.path + new Date();
    },
  },
};
</script>

<style lang="scss">
$main-color: #e62129;
#app {
  width: 100vw;
  height: 100vh;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #c4c4c4;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #c4c4c4;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c4c4c4;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #c4c4c4;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
