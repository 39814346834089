
export default [
    {
        path: "/order/:id",
        name: "orderDetail",
        component: () => import("@/views/orders/detail"),
        meta: {
            baseModule: 'service',
            isMenu: false,
            title: '定单详情',
            keepAlive: false,
            backIcon: 'back',
            roles: [],
            departmentCodes: []
        }
    },
    {
        path: "/order/:orderId/logistics/list",
        name: "logisticsByOrder",
        component: () => import("@/views/orders/logisticsByOrder"),
        meta: {
            baseModule: 'service',
            isMenu: false,
            title: '物流列表',
            keepAlive: false,
            backIcon: 'back',
            roles: [],
            departmentCodes: []
        }
    },
]