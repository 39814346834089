import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

import order from "./modules/order";
import logistics from "./modules/logistics";

export const constantRoutes = [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/Home"),
        meta: {
            isMenu: false,
            title: '首页',
            keepAlive: false,
            iconClass: 'home'
            // roles:["USER"]
        }
    },
    ...order,
    ...logistics,
    {
        path: '/401',
        name: '401',
        component: () => import("@/views/error-page/401"),
        meta: {
            isMenu: false,
            title: '401',
            keepAlive: true,
            iconClass: ''
        }
    },
    {
        path: '/404',
        name: '404',
        component: () => import("@/views/error-page/404"),
        meta: {
            isMenu: false,
            title: '404',
            keepAlive: true,
            backIcon: 'back'
        }
    },
    // {
    //     path: '/404',
    //     name: '404',
    //     component: layout,
    //     meta: {
    //         isMenu: false,
    //         title: '404',
    //         keepAlive: true,
    //         backIcon: 'back'
    //     },
    //     children: [
    //         {
    //             path: "",
    //             name: "404page",
    //             hidden: true,
    //             component: () => import("@/views/error-page/404"),
    //             meta: {
    //                 isMenu: false,
    //                 title: '404',
    //                 keepAlive: true,
    //                 backIcon: 'back'
    //             }
    //         }
    //     ]
    // },
    {
        path: '*',
        name: 'all',
        redirect: '/404',
        meta: {
            isMenu: false,
            title: '404noFound',
            keepAlive: true,
            iconClass: ''
        }
    }
]
const router = new Router({
    routes: constantRoutes
})

// 重置路由
export function resetRouter() {
    const newRouter = new Router({
        routes: constantRoutes
    });
    router.matcher = newRouter.matcher // reset router
}

export default router
