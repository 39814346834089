<template>
	<svg class="svg-icon" aria-hidden="true">
		<use :xlink:href="iconName"></use>
	</svg>
</template>
<script>
export default {
	name:'svgIcon',
	props:{
		iconClass:{
			type: String,
			required: true,
			default:""
		}
	},
	computed:{
		iconName(){
			return `#icon-${this.iconClass}`
		}
	}
}
</script>>
<style lang="scss" scoped>
.svg-icon {
  	width: 1em; 
	height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}
</style>