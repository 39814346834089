
export default [
    {
        path: "/logistics/:id/order/:orderId",
        name: "logisticsDetail",
        component: () => import("@/views/logistics/detail"),
        meta: {
            baseModule: 'service',
            isMenu: false,
            title: '物流详情',
            keepAlive: false,
            backIcon: 'back',
            roles: [],
            departmentCodes: []
        }
    },
]