
/**
* 创建唯一的字符串
* @return {string} ojgdvbvaua40
*/
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
* 获取url的参数
* param name 要获取的url的参数的键名
* var from = getQueryString("from");
*/
export function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]); return null;
}

/**
 * Filter asynchronous routing tables by recursion
 * @param fn 需要防抖的函数内容
 * @param interval 间隔时间
 */
export function debounce(fn, interval = 300) {
  let timeout = null;
  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn.apply(this, arguments);
    }, interval);
  };
}

/**
 * Filter asynchronous routing tables by recursion
 * @param fn 需要节流的函数内容
 * @param interval 间隔时间
 */
export function throttle(fn, interval = 300) {
  let canRun = true;
  return function () {
    if (!canRun) return;
    canRun = false;
    setTimeout(() => {
      fn.apply(this, arguments);
      canRun = true;
    }, interval);
  };
}

/**
* @param num 需要转换成千位分隔符显示的数值
*/
export function numFormat(num) {
  var res = num.toString().replace(/\d+/, function (n) { // 先提取整数部分
    return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
      return $1 + ",";
    });
  })
  return res;
}